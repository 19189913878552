import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModel } from './../../RequestForQuote/useModel';
import { useForm } from '../../../utils/useForm';
import { SSIUpdater } from '../../../services/ssiUpdaterService';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import FilteringInput from '../../input/FilteringInput';
import styles from './History.scss';
import ConfirmationModal from './modal';
import { currencies, getYesNoOptions } from './dropdownEnums';
import ValidatingSelect from '../../common/ValidatingSelect';

function CloseButton(props) {
    function handleClick(e) {
        e.preventDefault();
        e.stopPropagation();
        props.onClick();
    }
    return (
        <button onClick={handleClick} className="td-icon-button-delete td-icon-button-delete" aria-label="Close"><span className="visually-hidden">Close</span></button>
    );
} 

function getClients() {
    // note: this is not asny as we have the client list in memory
    return SSIUpdater.getClients();
}

function AccountChange({theKey, change, onUpdate, onDelete, hasSubmit, setError}) {
    const { t } = useTranslation();

    function onActionChange(action) {
        change.action = action;
        // wipe out the rest of the data in the change - but not client, because it might have been 
        // default and it's annoying to figure what that would be here
        change.accounts = change.transitNumber = change.account = change.accountNumber = change.name = change.currency = change.isThirdparty = change.isPayee = undefined;
        onUpdate();
    }

    function setMyError(e) {
        setError(theKey, e);
        // console.log('setMyError ', theKey);
    }

    return (
        <div>
            <Row className={`${styles["history-close-button"]}`}>
                <CloseButton onClick={onDelete} />
            </Row>
            <Row className={`${styles["history-leading-row"]}`}>
                <Col xs={12} sm={12} lg={2}>
                    <InputGroup>
                        <Form.Check xs={12} sm={12} lg={2}
                                    type="radio"
                                    id={`addAccount${theKey}`}
                                    name={`action${theKey}`}
                                    className={`${styles["radio-group1"]}`}
                                    label={t('td.fx.tradeSettlement.bankAccount.lbl.addAccount')}
                                    checked={change.action == 'add'}
                                    onChange={() => onActionChange('add')}
                        />
                        <Form.Check xs={12} sm={12} lg={2}
                                    type="radio"
                                    id={`removeAccount${theKey}`}
                                    name={`action${theKey}`}
                                    className={`${styles["radio-group2"]}`}
                                    label={t('td.fx.tradeSettlement.bankAccount.lbl.removeAccount')}
                                    checked={change.action == 'remove'}
                                    onChange={() => onActionChange('remove')}
                        />
                    </InputGroup>
                </Col>
                <Col xs={12} sm={12} lg={10}>
                    {change.action == 'add' ? 
                        <AddAccount change={change} onUpdate={onUpdate} hasSubmit={hasSubmit} setError={setMyError} />
                        :
                        <RemoveAccount change={change} onUpdate={onUpdate} hasSubmit={hasSubmit} setError={setMyError} />
                    }
                </Col>
            </Row>
        </div>
    );
}

function isCanadianClient(theClient) {
    return theClient?.deskCountry == 'Canada';
}


function AddAccount({change, onUpdate, hasSubmit, setError}) {
    const { t } = useTranslation();
    const yesNoOptions = getYesNoOptions();

    const { fieldErrors, setFieldError } = useForm(validate);

    useEffect(() => {
        validate();
    }, [change]);


    function validate(fields) {
        // if there are any field errors, then set the parent error
        fields = fields || fieldErrors;
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            setError(true);
        } else {
            setError(false);
        }
    }

    function handleChange(value, name) {
        change[name] = value;
        onUpdate();
    }

    const clients = useMemo(() => getClients(), []);

    function onClientChange(theClient) {
        const selectedClient = clients.find(o => theClient === o.code);
        change.client = selectedClient;
        onUpdate();
    }

    return (
        <>
        <Row className={`${styles["history-row"]}`}>
            <Form.Group as={Col} xs={12} sm={12} lg={12}>
                    <Form.Label>
                        {t('td.fx.tradeSettlement.bankAccount.lbl.clientId')}
                    </Form.Label>
                    <ValidatingSelect required
                                    errorPrefix={'td.validation.'}
                                    hideError={!hasSubmit}
                                    name={'code'}
                                    optionValue={'code'}
                                    optionText={'name'}
                                    optionList={clients}
                                    onChange={(e) => onClientChange(e?.target?.value)}
                                    setFieldError={setFieldError}
                                    value={change.client?.code || ''}/>
            </Form.Group>
        </Row>
        <Row className={`${styles["history-row"]}`}>
            <Form.Group as={Col} xs={12} sm={12} lg={8} className={`${styles["form-group-name-and-label"]}`}>
                <Form.Label>
                    {t('td.fx.tradeSettlement.bankAccount.lbl.name')}
                </Form.Label>
                <FilteringInput required
                                errorPrefix={'td.validation.'}
                                hideError={!hasSubmit}
                                name="name"
                                placeholder={''}
                                setFieldError={setFieldError}
                                update={(e) => handleChange(e, "name")}
                                value={change.name}/>
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={12} lg={4}>
                <Form.Label>
                    {t('td.fx.tradeSettlement.bankAccount.lbl.accountNumber')}
                </Form.Label>
                <FilteringInput required
                                errorPrefix={'td.validation.'}
                                hideError={!hasSubmit}
                                name="accountNumber"
                                placeholder={''}
                                setFieldError={setFieldError}
                                update={(e) => handleChange(e, "accountNumber")}
                                value={change.accountNumber}/>
            </Form.Group>
        </Row>
        <Row className={`${styles["history-row"]}`}>
            { isCanadianClient(change.client) ? // TD Bank NA onlys supports US accounts, and accounts don't have transits
                <>
                <Form.Group as={Col} xs={12} sm={12} lg={4}>                    
                    <Form.Label>
                        {t('td.fx.tradeSettlement.bankAccount.lbl.currency')}
                    </Form.Label>
                    <ValidatingSelect required
                        errorPrefix={'td.validation.'}
                        hideError={!hasSubmit}
                        name={'currency'}
                        optionList={currencies}
                        onChange={(e) => {
                            handleChange(e?.target?.value, 'currency');
                        }}
                        setFieldError={setFieldError}
                        value={change.currency || ''}/>
                </Form.Group>
                <Form.Group as={Col} xs={12} sm={12} lg={4}>
                    <Form.Label>
                        {t('td.fx.tradeSettlement.bankAccount.lbl.transitNumber')}
                    </Form.Label>
                    <FilteringInput required
                                    maxLength={5}
                                    regex={/^\d+$/}
                                    errorPrefix={'td.validation.'}
                                    hideError={!hasSubmit}
                                    name={'transitNumber'}
                                    placeholder={''}
                                    setFieldError={setFieldError}
                                    update={(e) => handleChange(e, "transitNumber")}
                                    value={change.transitNumber}/>
                </Form.Group>
                </> 
            : null }
        </Row>
        <Row className={`${styles["history-row"]}`}>
            <Form.Group as={Col} xs={6} sm={6} lg={6}>
                <Form.Label>
                    {t('td.fx.tradeSettlement.thirdPartyAccount')}
                </Form.Label>
                <ValidatingSelect required
                                errorPrefix={'td.validation.'}
                                hideError={!hasSubmit}
                                name={'isThirdparty'}
                                optionList={yesNoOptions}
                                onChange={(e) => {
                                    handleChange(e?.target?.value, 'isThirdparty');
                                    if (e?.target?.value == 'No') {
                                        setFieldError('isThirdparty', false);
                                        setFieldError('isPayee', false);
                                    }
                                }}
                                setFieldError={setFieldError}
                                value={change.isThirdparty || ''}/>
            </Form.Group>
            { change.isThirdparty === 'Yes' ? <>
                <Form.Group as={Col} xs={6} sm={6} lg={6}>
                    <Form.Label>
                        {t('td.fx.tradeSettlement.payee')}
                    </Form.Label>
                    <ValidatingSelect required
                                    errorPrefix={'td.validation.'}
                                    hideError={!hasSubmit}
                                    name={'isPayee'}
                                    optionList={yesNoOptions}
                                    onChange={(e) => handleChange(e?.target?.value, 'isPayee')}
                                    setFieldError={setFieldError}
                                    value={change.isPayee || ''}/>
                </Form.Group> 
            </> : null }
        </Row>
        </>
    );
}

function getAccounts(client) {
    // return a list of accounts with wire filtered out
    return client?.accounts.filter(a => !a.wire);
}

function RemoveAccount({change, onUpdate, hasSubmit, setError}) {
    const { t } = useTranslation();

    // duplication
    const { fieldErrors, setFieldError } = useForm(validate);
    function validate(fields) {
        // if there are any field errors, then set the parent error
        fields = fields || fieldErrors;
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            setError(true);
        } else {
            setError(false);
        }
    }



    const clients = useMemo(() => getClients(), []);

    useEffect(() => {
        // console.log('change changed');
        // if change has a client, it should also have teh account list
        if (change.client && !change.accounts) {
            change.accounts = getAccounts(change.client);
            onUpdate();
        }
        validate();
    }, [change]);

    function onClientChange(theClient) {
        const selectedClient = clients.find(o => theClient === o.code);

        change.client = selectedClient;
        change.accounts = getAccounts(change.client);
        onUpdate();
    }

    function handleAccountChange(value) {
        change.account = change.client?.accounts?.find(a => a.ssid == value);
        // update what we can
        // why even do this - we have the values in account already...
        change.accountNumber = change.account?.accountNumber;
        change.currency = change.account?.ccy;
        onUpdate();
    }

    function handleChange(value, name) {
        change[name] = value;
        onUpdate();
    }
    
    return (
        <>
        <Row className={`${styles["history-row"]}`}>
            <Form.Group as={Col} xs={12} sm={12} lg={12}>
                    <Form.Label>
                        {t('td.fx.tradeSettlement.bankAccount.lbl.clientId')}
                    </Form.Label>
                    <ValidatingSelect required
                                    errorPrefix={'td.validation.'}
                                    hideError={!hasSubmit}
                                    name={'code'}
                                    optionValue={'code'}
                                    optionText={'name'}
                                    optionList={clients}
                                    onChange={(e) => onClientChange(e?.target?.value)}
                                    setFieldError={setFieldError}
                                    value={change.client?.code || ''}/>
                </Form.Group>
        </Row>
        { change.client ? <>
        <Row className={`${styles["history-row"]}`}>
            <Form.Group as={Col} xs={12} sm={12} lg={8}>
                <Form.Label>
                    {t('td.fx.tradeSettlement.bankAccount.lbl.AccountName')}
                </Form.Label>
                <ValidatingSelect required
                    errorPrefix={'td.validation.'}
                    hideError={!hasSubmit}
                    name={'name'}
                    optionList={change?.accounts}
                    onChange={(e) => {
                        handleAccountChange(e?.target?.value);
                    }}
                    optionValue={'ssid'}
                    optionText={'displayName'}
                    setFieldError={setFieldError}
                    value={change.account?.ssid || ''}/>
                </Form.Group>
            </Row>
            <Row className={`${styles["history-row"]}`}>
                    <Form.Group as={Col} xs={12} sm={12} lg={4}>
                        <Form.Label>
                            {t('td.fx.tradeSettlement.bankAccount.lbl.currency')}
                        </Form.Label>
                        <FilteringInput required
                                    disabled={true}
                                    errorPrefix={'td.validation.'}
                                    hideError={!hasSubmit}
                                    name={`currency`}
                                    placeholder={''}
                                    setFieldError={setFieldError}
                                    update={(e) => {}}
                                    value={change.account?.ccy || ''}/>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={12} lg={6}>
                        <Form.Label>
                            {t('td.fx.tradeSettlement.bankAccount.lbl.accountNumber')}
                        </Form.Label>
                        <FilteringInput required
                                        disabled={true}
                                        errorPrefix={'td.validation.'}
                                        hideError={!hasSubmit}
                                        name={`accountNumber`}
                                        placeholder={''}
                                        setFieldError={setFieldError}
                                        update={(e) => handleChange(e, "accountNumber")}
                                        value={change.account?.number}/>
                    </Form.Group>
                </Row>
            </> : null }
        </>
    );
}

function newChange(clients) {
    return {action: 'add', client: (clients.length == 0 ? clients[0] : undefined) };
}

// FIXME - this is the wrong name for this file - should be add/remove account
export default function History() {
    const clients = useMemo(() => getClients(), []);

    const [error, setError] = useState(false);
    const [changes, setChanges] = useState(() => [newChange(clients)]);

    const [hasSubmit, setHasSubmit] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showProcessingModal, setShowProcessingModal] = useState(false);
    const [showClearModal, setShowClearModal] = useState(false);
    const [successfulSubmit, setSuccessfulSubmit] = useState(false);

    const { fieldErrors, setFieldError } = useForm(validate);
    const { t } = useTranslation();

    function onUpdate() {
        setChanges([...changes]);
    }

    function onDelete(index) {
        setChanges(changes.filter((o,i) => i !== index));
        // this potentially deleted a change with invalid fields
        // we need to clean up the field errors from this
        // but it's also going to change the order - so what really need to do is clear the errors all together
        // re-render is going to re-build all of the components after this anyway, so we really only need to wipe the last one
        // remember that changes is still the original at this point, as setChanges just queues the update
        setFieldError(changes.length - 1, undefined);
        // console.log(fieldErrors);
    }

    // change this to add a change
    function addRow() {
        const change = newChange(clients);

        changes.push(change);
        onUpdate();
    }

    function clear(e) {
        e.preventDefault();
        setShowClearModal(true);
    }

    function handleSubmit(e) {
        setHasSubmit(true);
        
        e.preventDefault();
        e.stopPropagation();
        
        if (error) {
            return false;
        }
        setShowConfirmation(true);
    }

    function validate(fields) {
        fields = fields || fieldErrors;
        // console.log('validate');
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            setError(true);
        } else {
            setError(false);
        }
        // console.log(fieldErrors);
    }

    function submitOnConfirmation() {
        SSIUpdater.submitBankAccountChanges(changes, () => setSuccessfulSubmit(true));
        setShowProcessingModal(true);
    }

    function clearOnConfirmation() {
        const newchanges = [newChange(clients)];
        setSuccessfulSubmit(false);
        setHasSubmit(false);
        setChanges(newchanges);
   }

    return (
        <div>
            <Container>
                {/* Confirmation modal */}
                <ConfirmationModal bodyText="td.fx.tradeSettlement.bankAccount.msg.submit.confirm"
                                   onConfirmation={submitOnConfirmation}
                                   display={showConfirmation}
                                   setDisplay={setShowConfirmation}/>
                {/* Processing time modal */}
                <ConfirmationModal bodyText="td.fx.tradeSettlement.wirePayment.msg.process"
                                   display={showProcessingModal}
                                   setDisplay={setShowProcessingModal}
                                   showCancel={false}
                                   large={true}
                                   onConfirmation={clearOnConfirmation}/>
                {/* Clear modal */}
                <ConfirmationModal bodyText="td.fx.tradeSettlement.msg.clear.confirm"
                                   onConfirmation={clearOnConfirmation}
                                   display={showClearModal}
                                   setDisplay={setShowClearModal}/>
                <Form noValidate onSubmit={handleSubmit}>
                    { (changes.length > 0) ?
                        changes.map((change, index) => <AccountChange key={index} theKey={index} change={change} onUpdate={onUpdate} onDelete={() => onDelete(index)} setError={setFieldError} hasSubmit={hasSubmit}/>) 
                        : null 
                    }
                    <Row className={`${styles["history-leading-row"]}`}>
                        <Col xs={6} sm={6} lg={8}>
                            {/* Add Row button */}
                            <Button className={`btn td-btn-primary-light ${styles["ar-button"]}`} 
                                onClick={(e) => {
                                    e.preventDefault();
                                    addRow();
                                }} >
                                <span className="td-icon-18x18 td-icon-18x18-add"></span>
                                {' ' + t('td.fx.tradeSettlement.bankAccount.btn.addRow')}
                             </Button>
                        </Col>
                        <Col xs={3} sm={3} lg={2}>
                            {/* Clear button */}
                            <Button className={`btn btn td-btn-secondary-clear  ${styles["ar-button"]}`} onClick={clear}>
                                {t('td.fx.tradeSettlement.btn.clear')}
                            </Button>
                        </Col>
                        <Col xs={3} sm={3} lg={2}>
                            {/* Submit button */}
                            <Button className={`btn td-btn-primary-light  ${styles["ar-button"]}`} type="submit">
                                {t('td.fx.tradeSettlement.btn.submit')}
                            </Button>
                        </Col>
                    </Row>
                    {(hasSubmit && successfulSubmit) ? <Row className={`${styles["bank-account-successful-submit"]}`}>
                        <Col>
                            <div>{t('td.fx.tradeSettlement.msg.submitted')}</div>
                        </Col>
                    </Row>: null}
                </Form>
            </Container>
        </div>
    );
}