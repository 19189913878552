import { Observer } from '../utils';
import { User } from './userService';

import { service as io } from './streamingService';

// Cuba, Iran, Syria, North Korea - copied from OnePortal - CountryService.ts
const SanctionedCountryISOCodes = ["CU", "IR", "SY", "KP"]; 

// These are returned by the country-code API but cannot be found 
// AN - Netherlands Antilles
// DG - Diego Garcia
const NOT_SUPPORTED = ["AN", "DG"];

function OnePortalService() {
    Observer.mixin(this);

    this.updateOnSubscribe = true;

    User.subscribe(() => {
        // update and publish
        this.registered = !!User.user?.mbun;
        this.finishedSetup = User.user?.opComplete; 
        this.legacyLogin = User.user?.legacyLogin;
        // if we are success but we've signed out, clear that
        if (!User.user) {
            this.success = false;
            this.error = false;
        }
        this.publish();
    });

    this.registered = false;
    this.finishedSetup = false;
    this.legacyLogin = false;
    

    this.shouldOpen = function() {
        let shouldMigrate = false;
        if (User.shouldMigrateDesk(this.migrate?.desks)){
            shouldMigrate= true;
        }else if (this.migrate?.country == "US") {
            shouldMigrate = User.isUSCustomer();
        } else if (this.migrate?.country == "ALL") {
            shouldMigrate = true;
        }

        return (this.legacyLogin || !this.registered) && shouldMigrate;
    };

    this.register = function(data) {
        console.log('registering: ', data);

        // we don't really throw exceptions here
        try {
            io.emit('onePortalRegister', data, (val) => {
                console.log(val);
                // update and publish
                if (val.success) {
                    // we need to distinguish between already registered and just registered
                    // that's what success does
                    this.success = true;
                } else {
                    // maybe make this better?
                    this.error = true;
                }
                this.publish();
            });
        } catch (err) {
            console.error(err);
            this.error = true;
            this.publish();
        }

    };

    this.getMigrate = () => {
        try {
            io.emit('onePortalMigrate', {}, (val) => {
                this.migrate = val.migrate;
                this.publish();
            });
        } catch (err) {
            console.error(err);
            this.error = true;
            this.publish();
        }
    };

    


    this.getCountryCodes = () => {
        try {
            io.emit('onePortalCountryCodes', {}, (val) => {
                let countries = val.supportedCountries?.filter(c => !SanctionedCountryISOCodes.includes(c.isoCode) && !NOT_SUPPORTED.includes(c.isoCode));
                this.publish(countries?.map(c => c.isoCode));
            });
        } catch (err) {
            console.error(err);
            this.error = true;
            this.publish();
        }
    };

    this.resendEmail = function () {
        try {
            io.emit('onePortalResendEmail', {}, (val) => {
                console.log(val);
                // update and publish
                if (val.success) {
                    // we need to distinguish between already registered and just registered
                    // that's what success does
                    // this.registered = true;
                    this.success = true;
                } else {
                    // maybe make this better?
                    this.error = true;
                }
                this.publish();
            });
        } catch (err) {
            console.error(err);
            this.error = true;
            this.publish();
        }
    };

    this.reopenModal = function () {
        this.reOpen = true;
        this.publish();
    }

}

export const OnePortal = new OnePortalService();