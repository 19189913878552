import { getDay, addDays, differenceInYears, addBusinessDays } from 'date-fns';
import { Hours } from '../../services/hoursService';
import utilities from '../../utils/';
import { User } from '../../services/userService';

const VALUE_DATE_TYPES = ["TDY", "TOM", "SPOT", "SPOT+1", "1W", "2W", "3W", "1M", "2M",
    "3M", "4M", "5M", "6M", "7M", "8M", "9M", "10M", "11M", "1Y", "18M", "2Y"
];

function getValueDateTypesBasedOnCurrency(types, isNA, isUSDCAD, isSPOT, isWireAcctSelected, company) {
    var homeBranchIsUS = company && company.deskCountry === 'United States';
    var defaultSelection = "TDY";
    var adjustedTypes = types;

    var removeTDY = false;
    var removeTOM = false;

    if (isUSDCAD) {
        removeTOM = true;
    } else if ((!isNA && (isWireAcctSelected || homeBranchIsUS))) {
        removeTDY = true;
        defaultSelection = "TOM";
    }

    if (isSPOT) {
        removeTDY = true;
        removeTOM = true;
        defaultSelection = "SPOT";
    }

    if (removeTOM) {
        var tomIndex = adjustedTypes.indexOf("TOM");
        if (tomIndex >= 0) {
            adjustedTypes = adjustedTypes.slice(0, tomIndex).concat(adjustedTypes.slice(tomIndex + 1));
        }
    }

    if (removeTDY) {
        var tdyIndex = adjustedTypes.indexOf("TDY");
        if (tdyIndex >= 0) {
            adjustedTypes = adjustedTypes.slice(0, tdyIndex).concat(adjustedTypes.slice(tdyIndex + 1));
        }
    }
    return {
        types: adjustedTypes,
        selection: defaultSelection
    };
}

export function getValueDateTypes(min, max, cur1, cur2, isWireAccountSelected, company) {
    const spotCCY = (User.user?.spotCCY || []);
    let isSPOT = (spotCCY.indexOf(cur1 + cur2) >= 0) || (spotCCY.indexOf(cur2 + cur1) >= 0)

    function isNA(cur) {
        return cur === 'CAD' || cur === 'USD' || cur == 'MXN';
    }

    var isNorthAmericanCurrency = isNA(cur1) && isNA(cur2);
    var isUSDCAD = cur1 != cur2 && (cur1 === 'CAD' || cur1 === 'USD') && (cur2 === 'CAD' || cur2 === 'USD');

    var types = VALUE_DATE_TYPES.slice(min ? VALUE_DATE_TYPES.indexOf(min) + 1 : 0,
        max ? VALUE_DATE_TYPES.indexOf(max) : VALUE_DATE_TYPES.length);

    // FIXME - this is getting ridiculous
    var data = getValueDateTypesBasedOnCurrency(types, isNorthAmericanCurrency, isUSDCAD, isSPOT, isWireAccountSelected, company);
    return data;
};

// FIXME - need to look at dateDate as well as dateValue
// should we pull in 2 field names, or just the field prefix and then add 'Date', 'Value' to it
export function getEndDateTenors(model, company, fieldNamePrefix) {
    const startTenor = getNextTenor(model[fieldNamePrefix + 'Type'], model[fieldNamePrefix + 'Date'], model.buyCurrency, model.sellCurrency);
    const tenors = getValueDateTypes(startTenor, false, model.buyCurrency, model.sellCurrency, false, company);
    return tenors.types.map(tenor => {
        return { text: tenor, value: tenor };
    });
}

export function isWeekday(date) {
    const day = getDay(date);
    return day !== 0 && day !== 6;
}

// this is shorter to type within this file
function isBusinessDate(theDate, cur1, cur2) {
    return Hours.HolidayCalendar.isBusinessDate(theDate, cur1, cur2);
}

export function isWeekend(date) {
    if (date) {
        var day = date.getDay();
        return day === 0 || day == 6;
    }
    return false;
}

// FIXME - this doesn't work when NZD rolls the date at 3pm(?)
// but we don't care.  The server is where things will be fixed.
export function getNextBusinessDate(date, cur1, cur2) {
    // don't change the date we passed in
    var nextDate = new Date(date.getTime());
    do {
        nextDate.setDate(nextDate.getDate() + 1);
    } while (!isBusinessDate(nextDate, cur1, cur2));
    return nextDate;            
}

export const getMinEndDate = (model, date, tenor) => {
    let theDate = date || getDateFromTenor(tenor, model.buyCurrency, model.sellCurrency);
    return getNextBusinessDate(theDate, model.buyCurrency, model.sellCurrency);
};

// swap and forward
// callers should wrap in useMemo
export function getMaxEndDate() {
    // today + 2 days + 10 years
    // realistically no clients have a credit line that goes out this far, so will most likely fail credit
    // check well before this date
    var date = Hours.getServerDate();
    date.setFullYear(date.getFullYear() + 10);
    // plus two more days for SPOT
    date.setDate(date.getDate() + 2);
    return date;
}

// 
export const getMaxWindowEndDate = (model, date, tenor) => {
    // if we have a tenor, set the date
    let theDate = date || getDateFromTenor(tenor, model.buyCurrency, model.sellCurrency);
    // if start date <= 1yr in the future
    // then window size <= 120 days
    // else if start date >= 1yr
    // then window size <= 31 days
    if (theDate && differenceInYears(theDate, new Date()) < 1) {
        return addDays(theDate, 120);
    }
    if (theDate && differenceInYears(theDate, new Date()) >= 1) {
        return addDays(theDate, 31);
    } 
};

// from legacy code - unclear if it is correct in all cases
export function getDateFromTenor(tenor, cur1, cur2) {
    var dateType = tenor;
    var date = Hours.getServerDate();
    if (dateType && cur1 && cur2) {
        var instrumentName = cur1 + cur2;
        var isUSDCAD = instrumentName && (instrumentName == "USDCAD" || instrumentName == "CADUSD");
        var SPOT = 2;
        if (isUSDCAD) {
            SPOT = 1;
            if (dateType == "TOM") {
                dateType = "SPOT";
            }
        }
        var i;
        switch (dateType) {
        case "TDY":
            // we know today is a business day
            return date;
        case "TOM":
            // already fixed for USDCAD
            date = getNextBusinessDate(date, cur1, cur2);                
            break;
        default:
            // Each of the following value dates start by first incrementing the date by SPOT: 
            for (i = 0; i < SPOT; i++) {
                date = getNextBusinessDate(date, cur1, cur2);
            }
            switch (dateType) {
            case "SPOT+1":
                date = getNextBusinessDate(date, cur1, cur2);
                break;
            case "SPOT+2":
                for (i = 0; i < 2; i++) {
                    date = getNextBusinessDate(date, cur1, cur2);
                }
                break;
            case "1W":
                date.setDate(date.getDate() + 7);
                break;
            case "2W":
                date.setDate(date.getDate() + 14);
                break;
            case "3W":
                date.setDate(date.getDate() + 21);
                break;
            case "1M":
                date.setMonth(date.getMonth() + 1);
                break;
            case "2M":
                date.setMonth(date.getMonth() + 2);
                break;
            case "3M":
                date.setMonth(date.getMonth() + 3);
                break;
            case "4M":
                date.setMonth(date.getMonth() + 4); 
                break;
            case "5M":
                date.setMonth(date.getMonth() + 5); 
                break;
            case "6M":
                date.setMonth(date.getMonth() + 6);       
                break;
            case "7M":
                date.setMonth(date.getMonth() + 7); 
                break;
            case "8M":
                date.setMonth(date.getMonth() + 8); 
                break;
            case "9M":
                date.setMonth(date.getMonth() + 9);
                break;
            case "10M":
                date.setMonth(date.getMonth() + 10);  
                break;
            case "11M":
                date.setMonth(date.getMonth() + 11);
                break;
            case "1Y":
                date.setYear(date.getFullYear() + 1);
                break;
            case "18M":
                date.setMonth(date.getMonth() + 18);
                break;
            case "2Y":
                date.setYear(date.getFullYear() + 2);
                break;
            default:
                // SPOT
                return date;
            }        
            if (!isBusinessDate(date, cur1, cur2)) {
                date = getNextBusinessDate(date, cur1, cur2);
            }
        }
    }
    return date;
}

export function getNextDate(tenor, date, cur1, cur2) {
    if (date) {
        return getNextBusinessDate(date, cur1, cur2);
    } else {
        return getNextBusinessDate(getDateFromTenor(tenor, cur1, cur2), cur1, cur2);
    }
}

export function getNextTenor(tenor, date, cur1, cur2) {
    var nextTenor = 'TOM';
    if (tenor) {
        nextTenor = VALUE_DATE_TYPES[VALUE_DATE_TYPES.indexOf(tenor)];
    } else if (date) {
        // FIXME - should use the same code as we use to pull date from tenor?
    }
    return nextTenor;
}

export function getMinDate(plusDays) {
    return addBusinessDays(Hours.getServerDate(), plusDays);
}