import { service as io } from './streamingService';
import { Hours } from './hoursService';
import { Observer } from '../utils';

function UserService() {
    this.working = false;

    this.login = function (data, done) {
        this.working = true;
        const body = JSON.stringify(data);
        fetch('/tdfx/login', {
            method: 'POST',
            headers: {
                Accept: 'text/plain',
                'Content-Type': 'application/json'
            },
            body: body
        }).then((res) => {
            if (res) {
                if (res.ok) {
                    res.json().then(data => {
                        if (!data.error) {
                            // we really get nothing here
                            // this.user = true;
                            // trigger getUser
                            if (data.onePortal && data.legacyLogin) {
                                this.working = false;
                                this.user = null;
                                this.publish();
                                done('one.portal');
                            }
                            this.getUser(done);
                        } else {
                            this.working = false;
                            this.user = null;
                            this.publish();
                            done(data.error);
                        }
                    });
                } else {
                    // that's not good
                    this.working = false;
                    this.publish();
                    done('connection');
                }
            } else {
                // FIXME - can this happen?
                this.working = false;
                this.publish();
                done('connection');
            }
        }).catch((res) => {
            // we are mixing null and false for user - not clear if someone is relying on this?
            this.user = null;
            this.working = false;
            this.publish();
            done('connection');
        });
    };

    this.timeout = false;
    this.logout = function (done, timeout, stopPublish) {
        // logout on the server
        fetch('/tdfx/forceLogout', { method: 'POST' }).then((res) => {
            done && done();
        });

        this.user = null;
        this.timeout = timeout;

        // when we're reloading the browser after logout we don't need to publish
        // publishing makes the browser delay the reload for a few seconds which looks weird.
        if (!stopPublish) {
            // shutdown the socket - this will publish
            io.disconnect();
            this.publish();
        }
    };

    // generally called BEFORE the socket is connected
    this.evict = false;
    this.getUser = function (done) {
        this.working = true;
        // trigger socket connection
        // callback is called once on connection
        io.connect(() => {
            // we're connected, send our user info request
            this.subscribeToSocketEvents();
            this.connected = true;
            io.emit('userinfo', {});

        });
        
        // it doesn't really makes sence to call the callback every time.
        // or at all really
        // this listener will stick around forever - which we kind of need for holiday updates
        // but starts to be weird when we logout and log back in.
        this.userDataListener = io.createListener('userdata', (data) => {
            if (data.errorCode) {
                done?.(data.errorCode);
            } else {
                this.processEvents(data);
                // probably already published because of setSelectedCompany
                this.publish();
            }
            this.working = false;
            if (this.userTimer) {
                clearTimeout(this.userTimer);
                this.userTimer = false;
            }
        });

        // check if we've got a response in 20 seconds - if not, we should give up
        let checkUser = () => {
            // potential timing issue if they login, then out, then in etc.?
            if (this.working && !this.user) {
                // not cool
                done?.('no.user');
            }
        };
        if (this.userTimer) {
            clearTimeout(this.userTimer);
            this.userTimer = false;
        }
        this.userTimer = setTimeout(checkUser, 20000);
    };

    // why do we handle currency holidays here?
    this.processEvents = function (data) {
        if (data) {
            if (data.currencyHolidays) {
                // console.log('handling currency holiday');
                Hours.HolidayCalendar.setCalendar(data.currencyHolidays);
            } else {
                this.user = data;
                Hours.setServerTime(this.user);
                this.fixCompanies(this.user);
                this.setSelectedCompany(this.user.companies[0]);
            }
        }
    };

    function compareCompany(a, b) {
        // same (possibly empty) priority
        // sort alphabetically - unless there are numbers at the end
        // e.g. sort abc 123 and abc 13 so that 13 comes before 123 - which is not what the alphabetic
        // sort would do
        var nameA = a.name;
        var nameB = b.name;
    
        if (nameA == nameB) {
            return 0;
        }
    
        var numericPattern = /[0-9]/g;
        var indexDigitInA = nameA.search(numericPattern);
        var indexDigitInB = nameB.search(numericPattern);
        // if digits start at the same index
        if (indexDigitInA > -1 && indexDigitInA == indexDigitInB) {
            var aSlice = nameA.slice(0, indexDigitInA);
            var bSlice = nameB.slice(0, indexDigitInB);
            // and the names are the same
            if (aSlice == bSlice) {
                // numerically compare the numbers after the name
                var nA = parseInt(nameA.substring(indexDigitInA), 10);
                var nB = parseInt(nameB.substring(indexDigitInB), 10);
                if (!isNaN(nA) && !isNaN(nB) && nA != nB) {
                    return nA > nB ? 1 : -1;
                }
            }
        }
        return nameA > nameB ? 1 : -1;
    }
    function compareAccount(a, b) {
        var nPriorityA = parseInt(a.orderid, 10);
        var nPriorityB = parseInt(b.orderid, 10);
    
        // if we have valid priorities, the higher priority comes first.
        // accounts without a priority come last.
        // all accounts will have a priority because this value represents the value from the db.
        if (!isNaN(nPriorityA) && !isNaN(nPriorityB)) {
            if (nPriorityA != nPriorityB) {
                return nPriorityA > nPriorityB ? 1 : -1;
            }
        } else if (!isNaN(nPriorityA)) {
            return -1;
        } else if (!isNaN(nPriorityB)) {
            return 1;
        }
    
        // FIXME - we never get here
        // orderid is a value representing the order of the accounts returned from the db query - (which
        // is ordered by priorityId)
    
        // same (possibly empty) priority
        // sort alphabetically - unless there are numbers at the end
        // e.g. sort abc 123 and abc 13 so that 13 comes before 123 - which is not what the alphabetic
        // sort would do
        var nameA = a.name;
        var nameB = b.name;
    
        if (nameA == nameB) {
            return 0;
        }
    
        var numericPattern = /[0-9]/g;
        var indexDigitInA = nameA.search(numericPattern);
        var indexDigitInB = nameB.search(numericPattern);
        // if digits start at the same index
        if (indexDigitInA > -1 && indexDigitInA == indexDigitInB) {
            var aSlice = nameA.slice(0, indexDigitInA);
            var bSlice = nameB.slice(0, indexDigitInB);
            // and the names are the same
            if (aSlice == bSlice) {
                // numerically compare the numbers after the name
                var nA = parseInt(nameA.substring(indexDigitInA), 10);
                var nB = parseInt(nameB.substring(indexDigitInB), 10);
                if (!isNaN(nA) && !isNaN(nB) && nA != nB) {
                    return nA > nB ? 1 : -1;
                }
            }
        }
        return nameA > nameB ? 1 : -1;
    }

    function fixAccounts(company) {
        if (!company.patchedAccounts) {
            company.patchedAccounts = true;

            let accounts = company.accounts;
            var i, j;
            for (i = 0; i < accounts.length; i++) {
                if (!accounts[i].displayName) {
                    accounts[i].displayName = accounts[i].name;
                }
                for (j = (i + 1); j < accounts.length; j++) {
                    if ((accounts[i].name === accounts[j].name) && (accounts[i].ccy === accounts[j].ccy)) {
                        accounts[i].displayName = '(' + accounts[i].number + ') ' + accounts[i].name;
                        accounts[j].displayName = '(' + accounts[j].number + ') ' + accounts[j].name;
                        break;
                    }
                }
            }

            // now sort the accounts
            company.accounts.sort(compareAccount);
        }

        // setup buy/sell accounts now too - so we don't have to worry about it
        company.buyAccounts = company.accounts.filter(acc => acc.isVisible && acc.isCredit);
        company.sellAccounts = company.accounts.filter(acc => acc.isVisible && acc.isDebit);
    }

    this.fixCompanies = function (data) {
        if (data && data.companies) {
            // remove all of the companies that are marked as disabled
            data.companies = data.companies.filter((c) => !c.disabled);

            // now sort them
            data.companies.sort(compareCompany);

            // now walk through the companies and fix the accounts and the currencies
            data.companies.forEach(c => fixAccounts(c));
        }
    };

    this.isUSCustomer = function() {
        return !!this.user?.companies?.find(function(d) { return d.deskCountry === "United States"; });
    };

    this.shouldMigrateDesk = function(desks) {
        if (!desks){
            return false;
        }
        return !!this.user?.companies?.find(function(d) { 
            
            const lettersToMigrate = desks[d.deskCode];
            const firstLetterOfClient = d.code.charAt(0);
            
            return  lettersToMigrate ? firstLetterOfClient <= lettersToMigrate : false});
    };

    this.ackCallback = function (data) {
        // not sure there's a lot we can do if the agreement fails?
        if (data?.success) {
            this.user.needsLegalAgreement = false;
            this.publish()
        }
    };

    this.ackLegalAgreement = function (isUSCustomer) {
        // re-use the channel listener
        io.emit('userUpdate', {
            ackLegalAgreement: true,
            isUSCustomer: isUSCustomer
        }, (data) => this.ackCallback(data?.data), 'userUpdate');
    };

    // not really true, but it's a better starting value
    this.connected = true;

    // when keep alive gives us a 403, we need to sync the client side
    this.cleanUpUser = function () {
        this.user = false;
        this.evict = false;
        this.publish();
    }

    // subscribe to events on the socket
    // but we can't really do that until the socket is connected
    // FIXME - think about this
    this.subscribeToSocketEvents = function () {
        io.onDisconnect((reason, details) => {
            // change our connection status
            console.log('socket disconnect', reason, details?.message, details?.description, details?.context);
            this.connected = false;
            this.publish();
        });
        io.onReconnect(() => {
            console.log('socket reconnect');
            this.connected = true;
            this.publish();
        });

        // do we really want to logout?
        // if we've openned a second tab, we'll be killing both

        let evictUser = (data) => {
            // console.log('evicting user');
            // we are kicked out - data has the reason
            // we get this even if we initiated the logout
            // if our user is already null, don't trigger this
            if (this.user) {
                this.logout(() => {
                    // don't wait for this
                });
                this.user = false;
                this.evict = true;
                this.publish();
            }
        };

        io.setNotifyOnOtherConnection(evictUser);

        // not convinced we actually get this?
        io.subscribe('DIE', evictUser);
        // console.log('subscribed to socket events');
    };

    this.canSettle = function () {
        return (this.user && this.user.permissions && this.user.permissions.SETTLE);
    };

    this.canDrawdown = function () {
        return (this.user && this.user.permissions && this.user.permissions.DRAWDOWN);
    };

    this.isInternalUser  = function() {
        return !this.user.client
    };

    this.setSelectedCompany = function(company) {
        let selected = this.user?.companies?.find(c => c.code === company.code);
        if (selected) {
            // note that we have the above outside this test because our first set is to our
            // current value
            // FIXME - that sounds like something we should fix
            if (this.selectedCompany != selected) {
                this.selectedCompany = selected;
                // make sure the selected company desk has the currency default set
                // FIXME - confirm country for usa
                selected.currencyDefault = (selected.deskCountry == 'United States' || selected.deskCountry == 'USA') ? 'USD' : 'CAD';
                Hours.setBranchHours(selected.branchData);
                // consider if we need to publish this?
                this.publish();
            }
        }
    }

    this.deskSpecificText = function(code) {
        const desks = [...new Set(this.user?.companies?.map(item => item.deskCode))];
        let keys = desks.map(function(x) { return code + '.' + x });
        keys.push(code);
        return keys
    };

    Observer.mixin(this);

    // this.user is a proxy for whether we are logged in
    this.user = false;
}

export const User = new UserService();
