import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'react-bootstrap';

import Footer from '../Footer';
import TDLogo from '../common/TDLogo';
import styles from './LoginForm.scss';

function SecurityQuestionsForm() {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div>
                <Row>
                    <div className={`td-error ${styles[`forgot-password-spinner`]}`}>
                    </div>
                </Row>
                <Row>
                    <h4>
                        {t('td.fx.forgot.password.cannot.retrieve.security.questions')}
                    </h4>
                </Row>
                <Row>
                    <h4></h4>
                </Row>
                <Row>
                    <h4>
                        {t('td.fx.forgot.password.one.portal')}
                    </h4>
                </Row>
                
                <Row>
                    <div className={`td-error ${styles[`forgot-password-spinner`]}`}>
                        <Button as="a" href="/" className="btn td-btn-secondary-clear btn">{t('td.fx.signing.cancel')}</Button>
                    </div>
                </Row>
            </div>
        </Fragment>
    );
}

function ForgotPassword() {
    return (
        <Row className={`${styles.fullHeight} + g-0`}>
            <Col xs={12} sm={6} md={5} lg={4} className={`g-0 ${styles.loginWrapper}`}>
                <Container style={{ padding: '4em', boxSizing: 'border-box' }}>
                    <TDLogo/>
                    <SecurityQuestionsForm/>
                </Container>
                <Footer login={true}/>
            </Col>
            <Col xs={12} sm={6} md={7} lg={8} className={`g-0 ${styles.loginBackground}`}>
            </Col>
        </Row>
    )
}

export default ForgotPassword;